import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PhoneInput from 'react-phone-input-2';
import Dialog from '../../../modules/components/Dialog';
import firebase from "firebase";
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom"
import AddAddress from "./AddAddress"

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectRedeemItems, selectRedeemTotal } from '../../../redux/redeem/redeem.selectors'
import { selectCurrentUser } from '../../../redux/user/user.selector'
import { selectProductItems } from '../../../redux/product/product.selectors';
import {
  setCurrentUser
} from '../../../redux/user/user.actions';
import {
  setRedeemItem
} from '../../../redux/redeem/redeem.actions';


const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  }
}));

const maxPod = 20;
const maxDevice = 6;

function Index(props) {

    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [currentCoupon, setCurrentCoupon] = React.useState(null);
    let history = useHistory();
    

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");
    const [mediaOption, setMediaOption] = React.useState("address_billing");
    const [selectedAddress, setSelectedAddress] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [errorShipping, setErrorShipping] = React.useState(false);
    const [errorBilling, setErrorBilling] = React.useState(false);

    useEffect(() => {
      setPhoneNumber(props.currentUser.phone)
    }, [props]);

    const saveAddressHandle = (address) => {

      var updates = {};

      if(props.currentUser.address){

        let totalAddress = [];

        props.currentUser.address.forEach((val, index)=>{
          if(address.type === val.type){
            totalAddress.push(val)
          }
        })

        if(totalAddress.length === 0){
          address.selected = true;
        }
        props.currentUser.address.push(address)

        if(address.selected){
          props.currentUser.address.forEach((val, index)=>{
            if(address.type === val.type && val !== address){
              val.selected = false;
            }
          })
        }

        updates['users/' + props.currentUser.id] = props.currentUser;
        
        firebase.database().ref().update(updates);
      }
      else{
        address.selected = true;
        props.currentUser.address = [address]

        updates['users/' + props.currentUser.id] = props.currentUser;
        
        firebase.database().ref().update(updates);
      }

      setOpenDialog(false)
    }

    const saveEditAddressHandle = (oldAddress, newAddress) => {
      var currentIndex = props.currentUser.address.map(val=> { return val}).indexOf(oldAddress);

      if(currentIndex !== -1){
        props.currentUser.address[currentIndex] = newAddress;
      }

      if(props.currentUser.address[currentIndex].selected){
        props.currentUser.address.forEach((val, index)=>{
          if(newAddress.type === val.type && val !== newAddress){
            val.selected = false;
          }
        })
      }

      var updates = {};
      updates['users/' + props.currentUser.id] = props.currentUser;
        
      firebase.database().ref().update(updates);

      setOpenDialog(false)
    }
    
    const placeOrderHandle = () => {
      setMediaOption("place_order")
      setDialogLoading(false)
      setAgreeButton(true)
      setDialogTitle("Confirm Redeem")
      setOptions("place_order")
      setDialogDes("Are you sure would like to confirm redeem? ")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

    const removeAddress = (val) => {
      setSelectedAddress(val)
      setMediaOption("remove_address")
      setDialogLoading(false)
      setAgreeButton(true)
      setDialogTitle("Remove Address")
      setOptions("removeItem")
      setDialogDes("Are you sure want to remove this address?")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

    const selectAddress = (type, value) => {

      props.currentUser.address.forEach((val)=>{
        if(val.type === type){
          if(val === value){
            val["selected"] = true;
          }
          else
          {
            val["selected"] = false;
          }
        }
        
      })

      var _currentUser = {...props.currentUser};

      props.setCurrentUser(_currentUser)
    }

    const renderComponent = () => {
      if(mediaOption === "address_billing"){
        return <AddAddress address={address} type={"billing"} 
        saveEditAddressHandle={(oldAddress, newAddress) => saveEditAddressHandle(oldAddress, newAddress)} 
        saveAddressHandle={(address)=> saveAddressHandle(address)}/>
      }
      else if(mediaOption === "address_shipping"){
        return <AddAddress address={address} type={"shipping"} 
        saveEditAddressHandle={(oldAddress, newAddress) => saveEditAddressHandle(oldAddress, newAddress)} 
        saveAddressHandle={(address)=> saveAddressHandle(address)}/>
      }
    }

    const addAddress = (type) => {
      setMediaOption("address_" + type)
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle("Add Address")
      setOptions("addItem")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
      setAddress(null)
    }

    const editAddress = (type, val) => {
      setMediaOption("address_" + type)
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle("Edit Address")
      setOptions("editItem")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
      setAddress(val)
    }

    const handleClose = () => {
      setOpenDialog(false)
    };

    const handleAgree = () => {

      var updates = {};

      if(mediaOption === "remove_address"){
        
        let currentAddress = props.currentUser.address.indexOf(selectedAddress);

        if (currentAddress > -1) {
          props.currentUser.address.splice(currentAddress, 1);
        }

        updates['users/' + props.currentUser.id] = props.currentUser;
        
        firebase.database().ref().update(updates);

      }
      else if(mediaOption === "place_order"){
        
        setLoading(true)

        let ordersRef = firebase.database().ref("gift_redeem").orderByKey().limitToLast(1);

        ordersRef.once('value', snapshot => {
          if(snapshot.exists()){
            let val = snapshot.val()

            saveOrder(parseInt(Object.values(val)[0].uuid) + 1)
          }
          else{
            saveOrder(1)
          }

        })

        
        
      }

      setOpenDialog(false)
    }

    const saveOrder = (num) => {

        var updates = {};

        var addItem = {};
        var newPostKey = firebase.database().ref().child("gift_redeem").push().key;


        addItem['created'] = firebase.database.ServerValue.TIMESTAMP;
        addItem['id'] = newPostKey;
        addItem['uuid'] = num;
        addItem['user'] = props.currentUser;
        addItem['user_uid'] = props.currentUser.id;
        addItem['products'] = renderRedeemItems();
        addItem['amount'] = 600;
        addItem['phone_number'] = phoneNumber;
        addItem['status_orders'] = "Order received";
        addItem['status_orders_serial'] = 1;
        addItem['coupon'] = currentCoupon;

        var billing_address = "";
        var shipping_address = "";

        if(props.currentUser.address){
          props.currentUser.address.forEach((val)=>{
            if(val.type === "billing" && val.selected){
              billing_address = val;
            }
            
            if(val.type === "shipping" && val.selected){
              shipping_address = val;
            }
          })
        }
        

        if(billing_address === ""){
          setErrorBilling(true)
        }
        else
        {
          setErrorBilling(false)
        }

        if(shipping_address === ""){
          setErrorShipping(true)
        }
        else
        {
          setErrorShipping(false)
        }


        var newPoint = 0;
        renderRedeemItems().forEach(val => {
          if(val.point){
            newPoint += val.point*val.quantity
          }
        })
        

        if(billing_address !== "" && shipping_address !== ""){

          addItem['billing_address'] = billing_address;
          addItem['shipping_address'] = shipping_address;
  
          updates["gift_redeem/" + newPostKey] = addItem;

          firebase.database().ref().update(updates).then(()=>{
            
            props.currentUser.point_users = Number(props.currentUser.point_users) + newPoint;
            props.currentUser.user_redeem_time = props.currentUser.user_redeem_time - 1;

            var users_updates = {}
            users_updates['users/' + props.currentUser.id] = props.currentUser;

            firebase.database().ref().update(users_updates).then(()=>{
              history.push("/giftredeem/"+ newPostKey)
            });
            
          })
  
          props.setRedeemItem([], props.currentUser)

        }
        else
        {
          setLoading(false)

        }
    }

    const renderAddressLayout = (type) => {
      return(
        <Grid style={{ backgroundColor: "#424242", padding: 30, marginTop: 20, borderRadius: 5}}>

            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Grid style={{ backgroundColor: "#99d700", borderRadius: 50, width: 30, height: 30, 
                display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography style={{ color: "black"}}>
                    3
                  </Typography>
                </Grid>
                {type === "shipping" ? <Typography style={{ marginLeft: 10 }}>
                  Shipping Address
                </Typography> : <Typography style={{ marginLeft: 10 }}>
                  Billing Address
                </Typography>}
              </Grid>
              <Grid>
                <IconButton size="small" onClick={()=>addAddress(type)}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 20 }}>
              {props.currentUser.address ? 
              props.currentUser.address.map((val, index)=> {

                let colorBorder = "1px solid " + (val.selected ? "#99d700": "#7e7e7e");

                return(
                  <Grid item md={4} xs={6} key={type + "-" + index} style={{ display: val.type === type ? "flex": "none"}}>
                      
                      <Grid style={{ border:colorBorder, borderRadius: 5, minHeight: 120, width: "100%" }}>
                        <Grid style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: 10 }}>
                          <IconButton style={{ marginRight: 10 }} size="small" onClick={()=>editAddress(type, val)} >
                            <EditIcon  style={{ fontSize: 12 }} />
                          </IconButton>
                          <IconButton size="small" onClick={()=> removeAddress(val)}>
                            <CloseIcon  style={{ fontSize: 12 }} />
                          </IconButton>
                        </Grid>
                        <ListItem onClick={()=> selectAddress(type, val)} button style={{ padding: 15 }}>
                          <Grid>
                            <Typography variant={"body2"}>
                            {val.title}
                            </Typography>
                            <Grid style={{ marginTop: 10, opacity:.7, overflow: "hidden", textOverflow: "ellipsis" }}>
                              <Typography variant={"caption"} >
                              {val.street_address + ", " + val.city + ", " + val.zip + " " + val.state + ", " + val.country}
                            </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                        
                      </Grid>

                  </Grid>
                )
                
              })
                : null}
            </Grid>
            <Grid>
              {type === "shipping" && errorShipping ? <Typography style={{ marginTop: 15, fontSize: 12 }} variant={"body2"} color="secondary" >
                {"Please select at least one shipping address"}
              </Typography>: null}
              {type === "billing" && errorBilling ? <Typography style={{ marginTop: 15, fontSize: 12 }} variant={"body2"} color="secondary" >
                {"Please select at least one billing address"}
              </Typography>: null}
            </Grid>
          </Grid>
      )
    }

    const verityItemTotal = () => {
      var activeButton = true;
      
      let totalPOD = 0;

      renderRedeemItems().forEach(val=>{
       
        if(val.type === "pod"){
          totalPOD = totalPOD + parseInt(val.quantity)
        }
        
      })
      
      let totalDevice = 0;

      renderRedeemItems().forEach(val=>{
       
        if(val.type === "device"){
          totalDevice = totalDevice + parseInt(val.quantity)
        }
        
      })

      if(totalPOD === maxPod && totalDevice === maxDevice && props.currentUser.user_redeem_time > 0){
        activeButton = false
      }

      return activeButton;
    }

    const renderRedeemItems = () => {
      let _redeemItems = []

      props.productItems.forEach(child =>{
        var currentIndex = props.redeemItems.map(val => val.id).indexOf(child.id);
        if(currentIndex !== -1 && child.status === "published" && child.redeem && child.quantity > 0){
          _redeemItems.push(props.redeemItems[currentIndex])
        }
      })

      return _redeemItems;
    }

    if(loading){
      return (
      <Grid className={classes.root}>
        <Grid style={{ marginTop: 70 }}>
          <CircularProgress 
            disableShrink
            color="primary" />
        </Grid>
      </Grid>);
    }
    

    return (
      <Grid className={classes.root}>
        <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          handleAgree={handleAgree}
          component={renderComponent()}
          handleClose={handleClose}/>

        <Grid container style={{ maxWidth: 1200 }}>
          <Grid item md={7} xs={12} style={{ padding : 20 }}>
              <Grid style={{ backgroundColor: "#424242", height: 150, padding: 30, borderRadius: 5 }}>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Grid style={{ backgroundColor: "#99d700", borderRadius: 50, width: 30, height: 30, 
                    display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Typography style={{ color: "black"}}>
                        1
                      </Typography>
                    </Grid>
                    <Typography style={{ marginLeft: 10 }}>
                      Contact Number
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <PhoneInput
                    containerStyle={{ marginTop:20 }}
                    dropdownStyle={{ color: "#1c1c1c" }}
                    disabled
                    country={'my'}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </Grid>
              </Grid>
              {renderAddressLayout("billing")}
              {renderAddressLayout("shipping")}

          </Grid>
          <Grid item md={5} xs={12}>
              <Grid style={{ padding: 30, display: "flex", alignItems: "center", flexDirection: "column"  }}>
                <Typography variant={"h6"}  style={{ opacity: .7 }}>
                    Your Redeem Items
                </Typography>
                <Grid container style={{ marginTop: 20 }}>
                    {renderRedeemItems().map((val, index)=>{
                      return(
                        <Grid key={"items-" + index} item xs={12} style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row"}}>
                            <Grid style={{ display:"flex", flexDirection: "row"}}>
                              <Typography variant={"body2"} style={{ opacity: .9, width: 30, fontWeight: "bold" }}>
                                  {val.quantity}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .7, width: 20 }}>
                                  {"x"}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .5, width: 150 }}>
                                  {val.name}
                              </Typography>
                            </Grid>

                            <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                              <Typography align="right" variant={"body2"} style={{ opacity: .7, width: 100, }}>
                                  {"RM " + val.sale_price * val.quantity}
                              </Typography>
                            </Grid>
                        </Grid>
                      )
                    })}
                </Grid>


                {currentCoupon ? <Grid container style={{ width: "100%", alignItems: "center", marginTop:10 }}>
                  <Grid item xs={6}>
                    <Typography  align="left" variant={"body2"} style={{ opacity: .7 }}>
                        {currentCoupon.name + " applied"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end"}}>
                    <Button onClick={()=> setCurrentCoupon(null)} style={{ opacity: .7, }}>
                        {"remove"}
                    </Button>
                  </Grid>
                </Grid> :  null}

                {currentCoupon && currentCoupon.coupon_type === "free_item" ? 
                <Grid container style={{ width: "100%", alignItems: "center", marginTop: 10 }}>
                  {currentCoupon.free_products.map((val, index)=>{
                      return(
                        <Grid key={"items-" + index} item xs={12} style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row"}}>
                            <Grid style={{ display:"flex", flexDirection: "row"}}>
                              <Typography variant={"body2"} style={{ opacity: .9, width: 30, fontWeight: "bold" }}>
                                  {val.quantity}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .7, width: 20 }}>
                                  {"x"}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .5, width: 150 }}>
                                  {val.name}
                              </Typography>
                            </Grid>

                            <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                              <Typography align="right" variant={"body2"} style={{ opacity: .7, width: 100, }}>
                                  {"FREE"}
                              </Typography>
                            </Grid>
                        </Grid>
                      )
                    })}
                </Grid> :  null}

                <Divider style={{width: "100%", marginTop: 20}}/>
                <Grid style={{ marginTop: 20,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"body2"} style={{ opacity: .9 }}>
                        {"Sub Total"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"body2"} style={{ opacity: .7, }}>
                        {"RM 600"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"body2"} style={{ opacity: .9 }}>
                        {"Tax"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"body2"} style={{ opacity: .7, }}>
                        {"RM 0"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"body2"} style={{ opacity: .9 }}>
                        {"Shipping"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"body2"} style={{ opacity: .7, }}>
                    {"Pending"}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider style={{width: "100%", marginTop: 20}}/>

                <Grid style={{ marginTop: 20,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"h6"} style={{ opacity: .9,  fontWeight: "bold" }}>
                        {"Total"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"h6"} style={{ opacity: .7, }}>
                    {"RM 600"}
                    </Typography>
                  </Grid>
                </Grid>
                  
                {/*<Grid style={{ width: "100%", marginTop: 10 }} >
                  <Typography align="left" variant={"body2"} style={{ opacity: .7, }}>
                        {"Direct Bank Transfer"}
                  </Typography>
                  <Typography align="left" variant={"body2"} style={{ opacity: .4, fontSize: 12}}>
                        {"Bank transfer (within 24 hours or subjected to cancellation) → screenshot of proof of payment → Whatsapp to +6012-345678 / email to admin@sp2smalaysia.com with title “payment proof for order ____”"}
                  </Typography>
                  </Grid>*/}

                <Button disabled={verityItemTotal()} onClick={placeOrderHandle} style={{ marginTop: 30, width: "100%" }} color="primary" disableElevation variant="contained">
                  Confirm Redeem
                </Button>
              </Grid>
              
          </Grid>
        </Grid>
      </Grid>
    );
  
}

const mapStateToProps = createStructuredSelector({
  redeemItems: selectRedeemItems,
  productItems: selectProductItems,
  total: selectRedeemTotal,
  currentUser: selectCurrentUser,
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
  setRedeemItem: (item, user) => dispatch(setRedeemItem(item, user))
});

export default connect( mapStateToProps, mapDispatchToProps )(Index);