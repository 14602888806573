import React, { useEffect }   from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { connect } from 'react-redux'
import { selectCurrentUser } from '../../../redux/user/user.selector'
import { createStructuredSelector } from 'reselect'

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  orderList: {
    display: "flex", 
    flexDirection: "column", 
    padding: 20, 
    paddingBottom: 20, 
    backgroundColor: "#363636", 
    border: "1px solid #7e7e7e", 
    borderRadius: 5, 
    height: 750, 
    overflow: "auto",
    [theme.breakpoints.down('md')]: {
      height: "100%", 
      border: "0px solid #7e7e7e", 
    },
  },
  orderListMc: {
    padding: 20, 
    [theme.breakpoints.down('md')]: {
      padding: 0
    },
  }
}));

function Index (props){
  

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [orderList, setOrderList] = React.useState([]);
  const [selectedOrder, setSelectedOrder] = React.useState(0);

  useEffect(() => {

    let ordersRef = firebase.database().ref('gift_redeem');

    ordersRef.on('value', snapshot => {

      if(snapshot.exists()){

        let orders = [];

        snapshot.forEach(child => {
            
          var itemsVal = child.val();
          if(itemsVal.user_uid === props.currentUser.id){
            orders.push(itemsVal);
          }
          
        })

        setOrderList(orders)
      }
      else{
        setOrderList([])
      }

      setLoading(false)
    })

  }, [props]);

  const renderOrderDetails = () => {

    return(
      <Grid style={{ display: "flex", flexDirection: "column", padding: 20, backgroundColor: "#363636", border: "1px solid #7e7e7e", borderRadius: 5 }}>
            <Grid container spacing={2} style={{ paddingBottom: 20, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
              <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ fontSize: 18 }} variant="body2">
                  {"Order Details"}
                </Typography>
                <Typography style={{ fontSize: 14, opacity: .6 }} variant="body2">
                  {orderList.length > 0 ? parseInt(orderList[selectedOrder].uuid).toLocaleString('en-US', {minimumIntegerDigits: 5, useGrouping:false}) : ""}
                </Typography>
              </Grid>
              
              <Grid item xs={12} >
                <Grid container spacing={2}>

                  {orderList.length > 0 &&  orderList[selectedOrder].invoices ? 
                  <Grid item xs={6} md={3}>
                    <Button style={{ width: "100%" }} onClick={()=> window.open(orderList[selectedOrder].invoices[0].url, "_blank")} color="primary" disableElevation variant="contained">
                      Invoice
                    </Button>
                  </Grid> : null }
                  
                  {orderList.length > 0 && orderList[selectedOrder].receipts ?
                  <Grid item xs={6} md={3}>
                    <Button style={{ width: "100%" }} onClick={()=> window.open(orderList[selectedOrder].receipts[0].url, "_blank")} color="primary" disableElevation variant="contained">
                      Receipt
                    </Button>
                  </Grid> : null }
                  
                  <Grid item xs={6} md={3}>
                    <Button style={{ width: "100%" }} component={Link}  to={'/giftredeem/' + (orderList.length > 0 ? orderList[selectedOrder].id : "")} color="primary" disableElevation variant="contained">
                    Details
                    </Button>
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid>

            <Divider light />
            <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Grid item xs={12} md={7}  style={{ paddingRight: 20 }}>
                <Typography variant="body2">
                  {"Shipping Address"}
                </Typography>
                <Typography style={{ fontSize: 12, opacity: .4, marginTop: 10,  }} variant="body2">
                    {orderList.length > 0 ? orderList[selectedOrder].shipping_address.title : ""}
                  </Typography>
                <Typography style={{ fontSize: 12, opacity: .6 }} variant="body2">
                  {orderList.length > 0 ? 
                  orderList[selectedOrder].shipping_address.street_address + ", " + 
                  orderList[selectedOrder].shipping_address.city + ", " + 
                  orderList[selectedOrder].shipping_address.zip + " " + 
                  orderList[selectedOrder].shipping_address.state + ", " + 
                  orderList[selectedOrder].shipping_address.country : ""}
                </Typography>
                <Typography style={{ marginTop: 10 }} variant="body2">
                  {"Billing Address"}
                </Typography>
                <Typography style={{ fontSize: 12, opacity: .4, marginTop: 10,  }} variant="body2">
                    {orderList.length > 0 ? orderList[selectedOrder].billing_address.title : ""}
                  </Typography>
                <Typography style={{ fontSize: 12, opacity: .6 }} variant="body2">
                  {orderList.length > 0 ? 
                  orderList[selectedOrder].billing_address.street_address + ", " + 
                  orderList[selectedOrder].billing_address.city + ", " + 
                  orderList[selectedOrder].billing_address.zip + " " + 
                  orderList[selectedOrder].billing_address.state + ", " + 
                  orderList[selectedOrder].billing_address.country : ""}
                </Typography>
                
              </Grid>
              
              <Grid item xs={12} md={5}>
                <Grid container>
                  <Grid item xs={6} >
                    <Typography variant="body2">
                      {"Sub Total"}
                    </Typography>
                    <Typography style={{ marginTop: 10 }} variant="body2">
                      {"Delivery Fee"}
                    </Typography>
                    <Typography style={{ marginTop: 10 }} variant="body2">
                      {"Tax"}
                    </Typography>
                    <Typography style={{ marginTop: 10, fontWeight: "bold" }} variant="body2">
                      {"Total"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                    <Typography variant="body2">
                    {orderList.length > 0 ? "RM " +orderList[selectedOrder].amount : "RM 0"}
                    </Typography>
                    <Typography style={{ marginTop: 10 }} variant="body2">
                      {orderList.length > 0 && orderList[selectedOrder].shipping_fee? "RM " + orderList[selectedOrder].shipping_fee : "Pending"}
                    </Typography>
                    <Typography style={{ marginTop: 10 }} variant="body2">
                      {"RM 0"}
                    </Typography>
                    <Typography style={{ marginTop: 10, fontWeight: "bold" }} variant="body2">
                    {orderList.length > 0 ? "RM " +orderList[selectedOrder].amount : "RM 0"}
                    </Typography>
                  </Grid>
                </Grid>
                
              </Grid>
              
            </Grid>

            <Grid>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.length > 0 ? orderList[selectedOrder].products.map((row, index) => (
                      <TableRow key={"item-"+index}>
                        <TableCell component="th" scope="row">
                          {row.image ? <img
                                        src={row.image[0].url}
                                        style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                        alt={row.name}
                                        /> : null}
                        </TableCell>
                        <TableCell align="left">
                          <Typography style={{ opacity:.6, fontSize: 12 }} variant="body2">
                            {row.name}
                          </Typography>
                          <Typography variant="body2">
                            {"RM" + row.sale_price}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">{row.quantity}</TableCell>
                      </TableRow>
                          )) : null}

                    {orderList.length > 0 && orderList[selectedOrder].coupon && orderList[selectedOrder].coupon.coupon_type === "free_item"
                    ? orderList[selectedOrder].coupon.free_products.map((row, index) => (
                      <TableRow key={"item-"+index}>
                        <TableCell component="th" scope="row">
                          {row.image ? <img
                                        src={row.image[0].url}
                                        style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                        alt={row.name}
                                        /> : null}
                        </TableCell>
                        <TableCell align="left">
                          <Typography style={{ opacity:.6, fontSize: 12 }} variant="body2">
                            {row.name}
                          </Typography>
                          <Typography variant="body2">
                            {"RM" + row.sale_price}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">{row.quantity}</TableCell>
                      </TableRow>
                          )) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            
          </Grid>
    )
  }

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }

  return (
    <Grid className={classes.root}>
      <Grid container >
        <Grid item sm={12} md={12} lg={5} className={classes.orderListMc} >
          <Grid className={classes.orderList}>
            <Typography style={{ fontSize: 18 }} variant="body2">
              {"My Redeemed Items"}
            </Typography>
            {orderList.sort((a, b) => b.created - a.created).map((val, index) => {

            let borderColor = selectedOrder === index ? "1px solid #99d700" : "1px solid #393939";

            return(
            <Grid key={"order-"+index} >
              <ListItem onClick={()=> setSelectedOrder(index)} button style={{ border:borderColor, borderRadius: 5, height: 200, marginTop: 15, padding: 0, backgroundColor: "#242424" }}>
                <Grid style={{ width: "100%", display: "flex", flexDirection: 'column'}}>
                  <Grid container style={{ padding: 15, display: "flex", flexDirection: "row"}} >
                    <Grid item xs={12}>
                      <Typography style={{ opacity: .6 }} variant={"body2"}>
                      {parseInt(val.uuid).toLocaleString('en-US', {minimumIntegerDigits: 5, useGrouping:false})}
                      </Typography>
                    </Grid>
                    <Grid item  xs={12}>
                      <Typography color="primary" variant={"caption"}>
                      {val.status_orders + (val.status_orders_serial === 9 ? val.courier === "lalamove"? " by Lalamove": " by JNT" : "")}
                      </Typography>
                      {val.status_orders_serial === 1 ? 
                      <Typography style={{ fontSize: 10, opacity: .6 }} variant={"body2"}>
                      {"We will confirm your redemption within 24 hours." }
                      </Typography> : null }
                      {val.status_orders_serial === 2 ? 
                      <Typography style={{ fontSize: 10, opacity: .6 }} variant={"body2"}>
                      {"We are processing your redemption." }
                      </Typography> : null }
                      {val.status_orders_serial === 3 && val.proof_of_payment ? 
                      <Typography style={{ fontSize: 10, opacity: .6 }} variant={"body2"}>
                      {"We will confirm your payment within 24 hours." }
                      </Typography> : null }
                      {val.status_orders_serial === 4 ? 
                      <Typography style={{ fontSize: 10, opacity: .6 }} variant={"body2"}>
                      {"Your redemption is handling by " + (val.handle_by_orders === "main_office"? "Main Office": "Sri Petaling Office")}
                      </Typography> : null }
                    </Grid>
                  
                  </Grid>
                  
                  <Divider light />
                  <Grid container style={{ padding: 15, display: "flex", flexDirection: "row"}} >
                    <Grid item xs={4}>
                      <Typography style={{ opacity: .6 }} variant={"body2"}>
                      {"Order Date"}
                      </Typography>
                      <Typography style={{ opacity: .6, marginTop: 10 }} variant={"body2"}>
                      {"Amout"}
                      </Typography>
                      <Typography style={{ opacity: .6, marginTop: 10 }} variant={"body2"}>
                      {"Total"}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography style={{ opacity: .6 }} variant={"body2"}>
                      {":"}
                      </Typography>
                      <Typography style={{ opacity: .6, marginTop: 10 }} variant={"body2"}>
                      {":"}
                      </Typography>
                      <Typography style={{ opacity: .6, marginTop: 10 }} variant={"body2"}>
                      {":"}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography style={{ opacity: .6 }} variant={"body2"}>
                      { new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' }).format(val.created)}
                      </Typography>
                      <Typography style={{ opacity: .6, marginTop: 10 }} variant={"body2"}>
                      {"RM " + val.amount}
                      </Typography>
                      <Typography style={{ opacity: .6, marginTop: 10 }} variant={"body2"}>
                      {"RM " + val.amount}
                      </Typography>
                    </Grid>
                  </Grid>

                </Grid>
              </ListItem>
              <Hidden lgUp>
                {selectedOrder === index ? 
                <Grid style={{ marginTop: 15 }}>
                  {renderOrderDetails()}
                </Grid> : null}
              </Hidden>
            </Grid>
            ) })}
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid  item sm={12} md={12} lg={7} style={{ padding: 20 }}  >
            {renderOrderDetails()}
          </Grid>
        </Hidden>
        
      </Grid>
    </Grid>
    
  );
  
}

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(Index);