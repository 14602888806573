import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialLink from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import HeaderUser from '../header/HeaderUser';
import Grid from '@material-ui/core/Grid';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CardMedia from '@material-ui/core/CardMedia';
import CloseIcon from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import LocalMall from '@material-ui/icons/LocalMall';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dialog from '../../modules/components/Dialog';
import Divider from '@material-ui/core/Divider';
import firebase from "firebase";

import { connect } from 'react-redux';
import {
  clearItemFromCart,
  addItem,
  removeItem,
  changeItemQuantity,
  setItem
} from '../../redux/cart/cart.actions';
import { createStructuredSelector } from 'reselect';
import { selectCartItems, selectCartTotal } from '../../redux/cart/cart.selectors'
import { selectCurrentUser } from '../../redux/user/user.selector'

//icons
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import InboxIcon from '@material-ui/icons/Inbox';
import Input from '@material-ui/core/Input';
import RedeemIcon from '@material-ui/icons/Redeem';
import moment from "moment";

function Copyright() {
  return (
    <Grid style={{ display: "flex", justifyContent:"center" }}>
      <Typography variant="caption" color="textSecondary" align="center">
        {'Copyright © '}
        <MaterialLink color="inherit" href="https://sp2smalaysia.com/">
          Sp2s Malaysia
        </MaterialLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Grid>
    
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    position: "absolute",
    left: "0",
    width: "100%",
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    display: 'flex',
  },
  footer: {
    padding: theme.spacing(2),
  },
  drawertxt: {
    fontSize: 14,
    flex: 1,
    display: 'flex',
    color: "white",
  },
  logo: {
    height: 60
  }
}));

function Index(props) {

  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [optionDrawer, setOptionDrawer] = React.useState("nav");
  const [open, setOpen] = React.useState(true);

  //Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");

  const [totalPurchase, setTotalPurchase] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);

  useEffect(() => {
    var itemsQuantity = 0;
    var _totalAmount_price1 = 0;
    var _totalAmount_price2 = 0;
    props.cartItems.forEach(val => {
      itemsQuantity += 1*val.quantity
      _totalAmount_price1 += val.sale_price*val.quantity
      _totalAmount_price2 += val.sale_price_2*val.quantity
    })

    var total_purchase = props.currentUser.total_ordered_products ? props.currentUser.total_ordered_products + itemsQuantity : itemsQuantity;
    setTotalPurchase(total_purchase)

    var now = moment();
    var expired_date = moment(props.currentUser.last_purchased).add(60, 'days');

    if(now.isBefore(expired_date) && total_purchase > 499){
      setTotalAmount(_totalAmount_price2)
    }
    else{
      setTotalAmount(_totalAmount_price1)
    }


  }, [props]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleAgree = () => {
    if(option === "logout"){
      firebase.auth().signOut();
    }

    setOpenDialog(false)
    setMobileOpen(false);
  }

  const handleSignout = () => {
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Logout")
    setOptions("logout")
    setDialogDes("You will be returned to the login screen")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const toggleDrawer  = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMobileOpen(open);
  }

  const handleDrawerToggle = (option) => {
    setOptionDrawer(option)
    setMobileOpen(!mobileOpen)
  };

  const navList = () => (
    <div style={{ height: "100%"}}>
      
      <List style={{ width: 250 }} disablePadding>

            <ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/"style={{ width: "100%", padding: 20 }}>
                <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <img
                        className={classes.logo}
                        src="/assets/logo/logo-white.png"
                        alt="Sp2s logo"
                      />
                </Grid>
            </ListItem>

            <Divider light />

            <ListItem button onClick={()=> setMobileOpen(false)}  component={Link} to="/redeem_products" >
              <ListItemIcon>
                <RedeemIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={
              <Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Redeem"}
                </Typography>} className={classes.drawertxt} />
            </ListItem>

            <ListItem button onClick={()=> setMobileOpen(false)}  component={Link} to="/offers" >
              <ListItemIcon>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={
              <Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Offers"}
                </Typography>} className={classes.drawertxt} />
            </ListItem>
            
            <ListItem button onClick={()=> setMobileOpen(false)}  component={Link} to="/faq">
              <ListItemIcon>
                <LiveHelpIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"FAQ"}
                </Typography>} className={classes.drawertxt} />
              
            </ListItem>

            <ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/request" >
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Request"}
                </Typography>} className={classes.drawertxt} />
              
            </ListItem>

            {<ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/generate_agent_id" >
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Generate Agent ID"}
                </Typography>} className={classes.drawertxt} />
              
              </ListItem>}

              {<ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/active_agent" >
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Agent"}
                </Typography>} className={classes.drawertxt} />
              
              </ListItem>}

              {<ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/generate_store_id" >
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Generate Store ID"}
                </Typography>} className={classes.drawertxt} />
              
              </ListItem>}

              {<ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/active_store" >
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText align="left" primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Store"}
                </Typography>} className={classes.drawertxt} />
              
              </ListItem>}
           
            <ListItem onClick={handleClick}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"My Account"}
                </Typography>} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List style={{ paddingLeft: 20 }} component="div" disablePadding>
                <ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/myprofile" className={classes.drawerlist}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"My Profile"}
                </Typography>} />
                </ListItem>
                <ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/myorders" className={classes.drawerlist}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"My Orders"}
                </Typography>} />
                </ListItem>
                <ListItem button onClick={()=> setMobileOpen(false)} component={Link} to="/giftredeem" className={classes.drawerlist}>
                  <ListItemIcon>
                    <RedeemIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Gift Redeem"}
                </Typography>} />
                </ListItem>
              </List>
            </Collapse>

            <Divider light />

            <ListItem button onClick={handleSignout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Logout"}
                </Typography>} />
            </ListItem>
      </List>
    </div>
  );

  const cartList = () => (
    <Grid style={{ width: "100%" }}>
      <Grid container style={{ width:  300}}>
         <Grid item xs={12} style={{ 
           top: 0,
           display: "flex", 
          justifyContent: "space-between", 
          backgroundColor: "#1c1c1c", zIndex: 99, 
          position: "sticky", padding: 20, 
          width: "100%", borderBottom: '1px solid #414141', alignItems: "center" }}>
              
              <Grid style={{ display: "flex", direction: "row"}}>
                <LocalMall color="primary" />
                <Typography style={{ fontSize: 16, paddingLeft: 20 }} variant="body2" color="textSecondary" component="p">
                  {props.cartItems.length + " Items"}
                </Typography>
              </Grid>
              <Grid>
                <IconButton
                  style={{ opacity: .7 }}
                  size={"small"}
                  onClick={()=> setMobileOpen(false)}
                >
                  <CloseIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Grid>
              
          </Grid>
          <Grid item xs={12}>
            <Grid container>
            
            {props.cartItems.map((val, index)=>{
              return(
                <Grid xs={12} key={"product-card-"+index} item style={{ borderBottom: '1px solid #414141' }}>
                  <Grid container style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: 10 }}>
                    
                    <Grid item xs={10}  style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", paddingLeft: 20 }} >
                      
                      <Grid style={{ paddingRight: 10 }}>
                        <Grid style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <IconButton
                              style={{ opacity: .7, width: 20, height: 20 }}
                              size={"small"}
                              onClick={()=> props.addItem(val, props.currentUser)}
                            >
                              <Add style={{ fontSize: 18 }} />
                            </IconButton>
                            <Grid style={{ height: 30, display: "flex", alignItems: "center" }} >
                              <Input 
                              type="text" 
                               inputProps={{min:1, style: { textAlign: 'center' }}}
                              style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center"  }} onChange={(e)=> { 

                                var isNumber = /^\d+$/.test(e.target.value)

                                if(!isNumber){
                                  return props.changeItemQuantity(e.target.value.replace(/\D/g,''), val, props.currentUser)
                                }
                                else
                                {
                                  return props.changeItemQuantity(e.target.value, val, props.currentUser)
                                }
                               }} value={val.quantity} />
                            </Grid>
                            <IconButton
                              style={{ opacity: .7, width: 20, height: 20 }}
                              size={"small"}
                              onClick={()=> props.removeItem(val)}
                            >
                              <Remove style={{ fontSize: 18 }} />
                            </IconButton>
                        </Grid>
                      </Grid>

                      <Grid >
                        <CardMedia
                            component="div"
                            style={{ width: 70, height: 70 }}
                            image={val.image ? val.image[0].url : null}
                            title={val.name}
                          />
                      </Grid>
                      <Grid style={{ paddingLeft: 10 }}>
                          <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Typography style={{ fontSize: 14 }} variant="body2" color="textSecondary" component="p">
                              {totalPurchase <= 499 ? "RM " + val.sale_price: "RM " + val.sale_price_2}
                            </Typography>
                          </Grid>
                          
                          <Typography style={{ opacity: .5, fontSize: 12, 
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis' }} variant="body2" color="textSecondary" component="p">
                            {val.name}
                          </Typography>

                          <Typography style={{ opacity: .5, fontSize: 12 }} variant="body2" color="textSecondary" component="p">
                            { "x " +val.quantity}
                          </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }} >
                      <IconButton
                        style={{ opacity: .7 }}
                        size={"small"}
                        onClick={()=> props.clearItem(val, props.currentUser)}
                      >
                        <CloseIcon style={{ fontSize: 18 }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid style={{ display: "flex", flexDirection: "row", paddingRight: 25, paddingBottom: 10, justifyContent: "flex-end" }}>
                      
                    <Typography style={{ fontSize: 16 }} variant="body2" color="textSecondary" component="p">
                        { totalPurchase <499 ? "RM " +val.quantity * val.sale_price : "RM " +val.quantity * val.sale_price_2}
                      </Typography>
                  </Grid>
                </Grid>
              )
            })}
            </Grid>
          </Grid>
          <Grid item style={{  width: "100%", padding: 20, bottom: 0, position: "sticky", backgroundColor: "#1c1c1c" }} >
          {props.cartItems.length !== 0 ? <Typography variant="h6" color="textSecondary" component="p" align="center">
            { "Total RM " +totalAmount.toFixed(2)}
          </Typography> : null}
          <Button onClick={()=> setMobileOpen(false)} component={Link}  to={'/checkout'} disabled={props.cartItems.length === 0 ? true: false} style={{ marginTop: 10, width: "100%" }} color="primary" disableElevation variant="contained">
            Checkout
          </Button>
      </Grid>
      </Grid>
     
    </Grid>
  );
  
  return (
    <Grid className={classes.root}>
      <Dialog 
        agreeTxt={"Yes"}
        disagreeTxt={"No"}
        description={dialogDes}
        title={dialogTitle}
        open={openDialog} 
        agreeButton={agreeButton}
        fullScreenDialog={fullScreenDialog}
        option={option}
        dialogLoading={dialogLoading}
        handleAgree={handleAgree}
        handleClose={handleClose}/>
      <CssBaseline />
      <SwipeableDrawer
            anchor={optionDrawer === "nav" ? 'left' : 'right'}
            open={mobileOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            disableBackdropTransition
          >
          {optionDrawer === "nav" ? navList() : cartList()}
      </SwipeableDrawer>
      <Grid className={classes.app}>
        <HeaderUser onDrawerToggle={(option)=> handleDrawerToggle(option)} />
        <main className={classes.main}>
          {props.component}
        </main>
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </Grid>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => ({
  clearItem: (item, user) => dispatch(clearItemFromCart(item, user)),
  addItem: (item, user) => dispatch(addItem(item, user)),
  setItem: (item, user) => dispatch(setItem(item, user)),
  changeItemQuantity: (quantity, item, user) => dispatch(changeItemQuantity(quantity, item, user)),
  removeItem: (item, user) => dispatch(removeItem(item, user))
});

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  currentUser: selectCurrentUser,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);
